<template>
  <v-dialog
    :value="true"
    width="400"
  >
    <v-card>
      <v-card-title>
        Attach Call
      </v-card-title>
      <v-card-text>
        Do you want to attach this call to a ticket, or a signup?
        <v-radio-group
          v-model="radioGroup"
        >
          <v-radio
            label="Ticket"
            value="ticket"
          />
          <v-radio
            label="Signup"
            value="signup"
          />
        </v-radio-group>
        <v-select
          v-if="radioGroup"
          v-model="selectedId"
          :label="selectLabel"
          :items="selectItems"
          :no-data-text="`No ${selectLabel} found`"
          item-text="description"
          item-value="id"
          outlined
          hide-details
        >
          <template v-slot:item="{ item, attrs, on }">
            <v-list-item
              v-bind="attrs"
              v-on="on"
            >
              <v-list-item-content>
                <v-list-item-title v-text="'ID #' + item.id + ' - ' + item.customerName" />
                <v-list-item-subtitle v-text="getFormattedDate(item.createdAt)" />
                <div>
                  {{ getDescriptionForDisplay(item.description) }}
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
      </v-card-text>
      <v-card-actions>
        <v-btn
          v-if="radioGroup && selectedId"
          color="green"
          @click="$close({ radioGroup, selectedId })"
        >
          Attach to {{ radioGroup }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { formatShortFullDate } from '@/shared/helpers/dayJsHelper';

export default {
  name: 'AttachCallModal',
  props: {
    customerData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      radioGroup: '',
      selectedId: null,
    };
  },
  computed: {
    selectLabel() {
      if (!this.radioGroup) return '';
      const firstChar = this.radioGroup.charAt(0).toUpperCase();
      const remainingChars = this.radioGroup.slice(1);

      return `${firstChar}${remainingChars}s`;
    },
    selectItems() {
      if (!this.radioGroup) return [];

      if (this.radioGroup.includes('ticket')) {
        return this.customerData.tickets;
      }

      if (this.radioGroup.includes('signup')) {
        return this.customerData.signups.map((signup) => ({
          id: signup.id,
          customerName: signup.customerName,
          createdAt: signup.createdAt,
          description: signup.customerAddress,
        }));
      }

      return [];
    },
  },
  watch: {
    radioGroup() {
      this.selectedId = null;
    },
  },
  mounted() {
    console.log(this.customerData);
  },
  methods: {
    getFormattedDate(date) {
      return formatShortFullDate(date);
    },
    getDescriptionForDisplay(description) {
      if (!description) return '';
      if (description.length > 100) {
        return `${description.substring(0, 97)}...`;
      }
      return description;
    },
  },
};
</script>
<style scoped>
</style>
