<template>
  <v-container
    fill-height
  >
    <v-layout
      align-center
      justify-center
    >
      <v-flex
        class="login-form"
      >
        <v-card>
          <v-card-text>
            <v-form
              ref="loginForm"
              v-model="valid"
              @keyup.enter="login()"
            >
              <v-text-field
                v-model="email"
                :rules="[rules.required, rules.emailAddress]"
                label="Email Address"
                required
                @keyup.enter="login()"
              />
              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required]"
                :type="showPassword ? 'text' : 'password'"
                name="input-10-1"
                label="Password"
                hint="At least 8 characters"
                counter
                @click:append="showPassword = !showPassword"
                @keyup.enter="login()"
              />
              <v-btn
                :disabled="!valid"
                @click="login()"
                @keyup.enter="login()"
              >
                Login
              </v-btn>
            </v-form>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
    <v-snackbar />
  </v-container>
</template>

<script>
import { EMAIL_REGEX } from '../shared/constants/regex';

export default {
  data() {
    return {
      valid: true,
      email: '',
      password: '',
      showPassword: false,
      rules: {
        required: (value) => !!value || 'Required.',
        emailAddress: (v) => v.match(EMAIL_REGEX) != null || 'Must be a valid email address.',
      },
      snackbar: {
        visible: false,
        text: '',
        timeout: 2000,
      },
    };
  },
  methods: {
    showToast(message) {
      this.snackbar.text = message;
      this.snackbar.visible = true;
    },
    login() {
      const { email } = this;
      const { password } = this;
      this.$store.dispatch('login', { email, password })
        .then(() => this.$router.push('/'))
        .then(() => window.location.reload())
        .catch((err) => this.showToast(err));
    },
  },
};
</script>

<style lang="scss" scoped>
  .login-form {
    max-width: 500px;
  }
</style>
