<template>
<div>
  <GoogleMap
    apiKey="AIzaSyCwjx2P15c-n74-1-DQbdsAM5oB8I-vRS0"
    :mapConfig="mapConfig"
  >
    <template slot-scope="{ google, map }">
      <GoogleMapMarker
        v-for="marker in markers"
        :key="marker.id"
        :marker="marker"
        :google="google"
        :map="map"
        @click="showMenu(marker, $event)"
      />
      <GoogleMapLine
        v-for="line in lines"
        :key="line.id"
        :path.sync="line.path"
        :google="google"
        :map="map"
      />
    </template>
  </GoogleMap>
  <v-menu
    v-model="show"
    absolute
    top
    :close-on-content-click="false"
    :position-x="x"
    :position-y="y"
    >
      <v-card
        v-if="selectedAddress"
        width="200"
        >
        <v-card-text>
          <span>{{ selectedAddress.address }}</span><br />
          <v-chip>{{ getStatusText(selectedAddress.status) }}</v-chip>
        </v-card-text>
      </v-card>
  </v-menu>
  </div>
</template>

<script>
import { mapSettings } from '@/shared/constants/mapSettings';
import { getLocationInfo } from '@/shared/apis/googleMapApi';
import { getMapAddresses } from '@/shared/apis/dashboardApi';
import { getStatusText } from '@/shared/helpers/ticketHelper';
import GoogleMap from './GoogleMap.vue';
import GoogleMapMarker from './GoogleMapMarker.vue';
import GoogleMapLine from './GoogleMapLine.vue';

export default {
  name: 'dashboard-map',
  components: {
    GoogleMap,
    GoogleMapMarker,
    GoogleMapLine,
  },
  data() {
    return {
      ticketAddresses: [],
      selectedAddress: null,
      markers: [],
      lines: [],
      show: false,
      x: null,
      y: null,
    };
  },
  computed: {
    mapConfig() {
      return {
        ...mapSettings,
        center: this.mapCenter,
      };
    },
    mapCenter() {
      return { lat: 49.4908798, lng: -121.5180136 };
    },
  },
  async created() {
    this.markers = await this.getMarkers();
  },
  methods: {
    async getMarkers() {
      this.ticketAddresses = await getMapAddresses();

      const markers = [];

      for (let i = 0; i < this.ticketAddresses.length; i += 1) {
        // { id: 'a', position: { lat: 3, lng: 101 } },
        // eslint-disable-next-line no-await-in-loop
        const data = await getLocationInfo(this.ticketAddresses[i].address);
        const { location } = data.results[0].geometry;
        markers.push({
          id: `${this.ticketAddresses[i].ticketId}`,
          position: location,
          data: this.ticketAddresses[i],
        });
      }
      return markers;
    },
    showMenu(marker, e) {
      this.x = e.domEvent.clientX;
      this.y = e.domEvent.clientY;
      this.selectedAddress = marker.data;
      this.show = true;
    },
    getStatusText(statusId) {
      return getStatusText(statusId);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
