import { HttpHelper } from '../helpers/httpHelper';

export async function getTicket(id) {
  const response = await HttpHelper.get(`/api/v1/tickets/${id}`);
  return response.data;
}
export async function getTicketsByCustomerId(customerId) {
  const response = await HttpHelper.get(`/api/v1/tickets/get_by_customer_id/${customerId}`);
  return response.data;
}

export async function getTicketsByPhoneNumber(phoneNumber) {
  const response = await HttpHelper.get(`/api/v1/tickets/get_by_phone_number/${phoneNumber}`);
  return response.data;
}
export async function getTicketsForCurrentUser() {
  const response = await HttpHelper.get('/api/v1/tickets/get_created_by_current_user');
  return response.data;
}

export async function getTicketsForCurrentCompany() {
  const response = await HttpHelper.get('/api/v1/tickets/get_for_current_company');
  return response.data;
}

export async function createTicket(data) {
  const response = await HttpHelper.post('/api/v1/tickets', data);
  return response.data;
}

export async function updateTicket(id, data) {
  const response = await HttpHelper.put(`/api/v1/tickets/${id}`, data);
  return response.data;
}

export async function createNote(data) {
  const response = await HttpHelper.post('/api/v1/notes', data);
  return response.data;
}

export async function createAttachment(data) {
  const response = await HttpHelper.post('/api/v1/tickets/attach', data);
  return response.data;
}

export async function getManagers() {
  const response = await HttpHelper.get('/api/v1/tickets/get_managers');
  return response.data;
}

export async function getTotalInteractions(startDate, endDate) {
  const response = await HttpHelper.get(`/api/v1/tickets/total_interactions?startDate=${startDate}&endDate=${endDate}`);
  return response.data;
}

export async function attachCallToTicket(data) {
  const response = await HttpHelper.post('/api/v1/tickets/attach_call_to_ticket', data);
  return response.data;
}
