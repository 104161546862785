/* eslint-disable import/prefer-default-export */
import { HttpHelper } from '@/shared/helpers/httpHelper';

export async function getCompanies() {
  const response = await HttpHelper.get('/api/v1/companies');
  return response.data;
}

export async function getCompany(id) {
  const response = await HttpHelper.get(`/api/v1/companies/${id}`);
  return response.data;
}

export async function createCompany(data) {
  const response = await HttpHelper.post('/api/v1/companies', data);
  return response.data;
}

export async function updateCompany(id, data) {
  const response = await HttpHelper.put(`/api/v1/companies/${id}`, data);
  return response.data;
}
