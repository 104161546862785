<template>
  <v-container>
    <v-row>
      <v-col>
        <v-select
          v-model="selectedBuildCommunity"
          label="Build Community"
          dense
          :items="buildCommunities"
          item-text="name"
          item-value="id"
          outlined
        />
      </v-col>
    </v-row>
    <v-row
      class="equal-height"
    >
      <v-col
        v-for="plan in plans"
        :key="plan.title"
        cols="12"
        md="4"
      >
        <v-card
          class="d-flex flex-column"
          style="height:100%"
        >
          <v-card-subtitle class="pb-0">
            {{ $store.state.user.user.company }}
          </v-card-subtitle>
          <v-card-title class="pt-0">
            {{ plan.name }}
          </v-card-title>
          <div class="mx-8">
            <hr>
            <v-card-subtitle class="text-h3">
              {{ plan.downloadSpeed }}/{{ plan.uploadSpeed }} Mbps
            </v-card-subtitle>
            <hr>
          </div>

          <v-card-text>
            <v-list>
              <v-list-item
                v-for="benefit in formatBenefits(plan)"
                :key="benefit"
              >
                <v-list-item-icon>
                  <v-icon>mdi-check</v-icon>
                </v-list-item-icon>
                <v-list-item-content>{{ benefit }}</v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <div class="mx-8">
            <hr>
            <div class="d-flex justify-content-space-between align-end">
              <v-card-title class="text-h3">
                ${{ formatPrice(plan.promoPrice) }}
              </v-card-title>
              <span>per month + install fee</span>
            </div>
          </div>
          <div class="d-flex flex-column mx-8">
            <v-btn
              color="primary"
              @click="$router.push({ name: 'new_sale', params: { planId: plan.id }})"
            >
              Get Started
            </v-btn>
            <span class="grey--text mt-3 d-flex justify-space-around">
              Price increases to ${{ formatPrice(plan.price) }} after {{ plan.promoLength }} months.
            </span>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        class="d-flex align-center"
      >
        <ul class="text-subtitle-2">
          <li>There is no cost for this improvement and no purchase is necessary.</li>
          <li>
            Care will be taken to restore the property to comparable condition.
            Proper growing conditions, in addition to help from residents (i.e., watering)
            may be necessary to restore vegetation, particularly grass.
          </li>
          <li>
            Service will be directed towards the area of your home/building closest to
            other utilities (i.e., power) and the building's mechanical room as practicable.
          </li>
          <li>
            Existing infrastructure, landscaping, ground and soil conditions,
            and safety will all be taken into consideration.
          </li>
          <li>
            You have the right to ask for a representative to
            visit your home prior to providing your consent.
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getBuildCommunities } from '@/shared/apis/buildCommunityApi';
import { getPlans } from '@/shared/apis/planApi';

export default {
  name: 'SalesLanding',
  data() {
    return {
      buildCommunities: [],
      selectedBuildCommunity: null,
      plans: [],
    };
  },
  watch: {
    async selectedBuildCommunity() {
      await this.getPlans();
    },
  },
  async created() {
    await this.getBuildCommunities();
  },
  methods: {
    async getBuildCommunities() {
      this.buildCommunities = await getBuildCommunities();
      this.selectedBuildCommunity = this.buildCommunities[0].id;
    },
    async getPlans() {
      this.plans = await getPlans(this.selectedBuildCommunity);
    },
    formatBenefits(plan) {
      const splitBenefits = plan.benefits.split('\n');
      return splitBenefits;
    },
    formatPrice(price) {
      return parseFloat(price).toFixed(2);
    },
  },
};
</script>

<style lang="scss" scoped></style>
