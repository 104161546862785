import { Device } from 'twilio-client';
import TWILIO_STATUS from '../shared/constants/twilio/twilioStatus';

export default {
  state: () => ({
    status: TWILIO_STATUS.NOT_READY,
    device: new Device(),
    connection: null,
  }),
  mutations: {
    update_status(state, data) {
      state.status = data.status;
    },
    create_connection(state, data) {
      state.device.connect(data);
      state.status = TWILIO_STATUS.IN_PROGRESS;
    },
    update_connection(state, data) {
      state.connection = data.connection;
    },
    destroy_connection(state) {
      state.device.disconnectAll();
      state.connection = null;
      state.status = TWILIO_STATUS.NOT_READY;
    },
    accept_connection(state) {
      state.connection.accept();
      state.status = TWILIO_STATUS.IN_PROGRESS;
    },
    destroy_device(state) {
      state.device.destroy();
    },
  },
  actions: {
    setup_device({ state, commit }, data) {
      state.device.setup(data.token, data.options);
      state.device.on('ready', () => {
        console.log('Connected');
        commit('update_status', { status: TWILIO_STATUS.READY });
      });

      state.device.on('error', (error) => {
        console.log(error.message);
      });

      state.device.on('connect', (conn) => {
        console.log('Calling...');
        commit('update_connection', { connection: conn });
        conn.on('hangup', (err) => {
          console.log(err);
          commit('destroy_connection');
          commit('update_status', { status: TWILIO_STATUS.READY });
        });
      });

      state.device.on('disconnect', () => {
        console.log('Call ended.');
        commit('destroy_connection');
        commit('update_status', { status: TWILIO_STATUS.READY });
      });

      state.device.on('incoming', (conn) => {
        console.log('Incoming call.');
        commit('update_connection', { connection: conn });
        commit('update_status', { status: TWILIO_STATUS.RINGING });
        console.log('Incoming Call');
      });
    },
    destroy_device({ commit }) {
      commit('destroy_connection');
      commit('destroy_device');
    },
  },
  getters: {},
};
