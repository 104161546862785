<template>
  <v-card
      class="mx-auto"
      :color="color"
      dark
      max-width="400"
    >
      <v-card-title class="d-flex justify-space-around">
        <!-- <v-icon
          large
          left
        >
          mdi-twitter
        </v-icon> -->
        <span class="title font-weight-light"> {{ title }}</span>
      </v-card-title>

      <v-card-text class="headline font-weight-bold text-center">
        {{ count }}
      </v-card-text>

      <v-card-actions>
      </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'info-card',
  props: {
    title: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
  },
  components: {},
  data() {
    return {

    };
  },
};
</script>

<style lang="scss" scoped>

</style>
