import Vue from 'vue';
import VueRouter from 'vue-router';
import Users from '@/views/Users.vue';
import Page from '@/views/Page.vue';
import Dashboard from '@/views/Dashboard.vue';
import Login from '@/views/Login.vue';
import Logout from '@/views/Logout.vue';
import Customers from '@/views/Customers.vue';
import AssignedTickets from '@/views/AssignedTickets.vue';
import Companies from '@/views/Companies.vue';
import Signups from '@/views/Signups.vue';
import NewSignup from '@/views/NewSignup.vue';
import SalesLanding from '@/views/SalesLanding.vue';
import NewSale from '@/views/NewSale.vue';
import Sales from '@/views/Sales.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Page,
    children: [
      {
        path: '',
        name: 'dashboard',
        component: Dashboard,
        meta: {
          auth: true,
        },
      },
      {
        path: '/directory',
        name: 'directory',
        component: Customers,
      },
      {
        path: '/tickets',
        name: 'tickets',
        component: AssignedTickets,
      },
      {
        path: '/sales',
        name: 'sales',
        component: Sales,
      },
      {
        path: '/saleslanding',
        name: 'sales_landing',
        component: SalesLanding,
      },
      {
        path: '/sales/new/:planId',
        name: 'new_sale',
        component: NewSale,
        props: (route) => {
          const planId = parseInt(route.params.planId, 10);
          if (Number.isNaN(planId)) return 0;
          return { planId };
        },
      },
      {
        path: '/signups',
        name: 'signups',
        component: Signups,
      },
      {
        path: '/signups/new',
        name: 'new_signup',
        component: NewSignup,
      },
      {
        path: '/signups/:signupId',
        name: 'edit_signup',
        props: (route) => {
          const signupId = parseInt(route.params.signupId, 10);
          if (Number.isNaN(signupId)) return 0;
          return { signupId };
        },
        component: () => import(/* webpackChunkName: "customer" */ '../views/EditSignup.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/settings',
        name: 'settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
      },
      {
        path: '/users',
        name: 'users',
        component: Users,
      },
      {
        path: '/user/:id',
        name: 'profile',
        component: () => import(/* webpackChunkName: "customer" */ '../views/UserProfile.vue'),
        props: true,
        meta: {
          auth: true,
        },
      },
      {
        path: '/companies',
        name: 'companies',
        component: Companies,
      },
      {
        path: '/company/:id',
        name: 'company',
        component: () => import(/* webpackChunkName: "company" */ '../views/Company.vue'),
      },
      {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
      },
      {
        path: '/customer/:id',
        name: 'customer',
        component: () => import(/* webpackChunkName: "customer" */ '../views/Customer.vue'),
        props: true,
        meta: {
          auth: true,
        },
      },
      {
        path: '/customer/:customerId/ticket/new',
        name: 'new_ticket',
        props: (route) => {
          const customerId = parseInt(route.params.customerId, 10);
          if (Number.isNaN(customerId)) return 0;
          return { customerId };
        },
        component: () => import(/* webpackChunkName: "customer" */ '../views/NewTicket.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/customer/:customerId/ticket/edit/:ticketId',
        name: 'edit_ticket',
        props: (route) => {
          const customerId = parseInt(route.params.customerId, 10);
          const ticketId = parseInt(route.params.ticketId, 10);
          if (Number.isNaN(customerId) || Number.isNaN(ticketId)) return 0;
          return { ticketId, customerId };
        },
        component: () => import(/* webpackChunkName: "customer" */ '../views/EditTicket.vue'),
        meta: {
          auth: true,
        },
      },
      {
        path: '/policies',
        name: 'policies',
        component: () => import(/* webpackChunkName: "policies" */ '../views/Policies.vue'),
      },
    ],
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
