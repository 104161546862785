<template>
  <div>
    <canvas
      ref="canvas"
      width="400"
      height="200"
    />
    <button @click="clearCanvas">
      Clear
    </button>
  </div>
</template>

<script>
export default {
  mounted() {
    this.canvas = this.$refs.canvas;
    this.ctx = this.canvas.getContext('2d');
    this.isDrawing = false;
    this.lastX = 0;
    this.lastY = 0;

    this.canvas.addEventListener('mousedown', this.startDrawing);
    this.canvas.addEventListener('mousemove', this.continueDrawing);
    this.canvas.addEventListener('mouseup', this.stopDrawing);

    this.canvas.addEventListener('touchstart', this.startDrawing);
    this.canvas.addEventListener('touchmove', this.continueDrawing);
    this.canvas.addEventListener('touchend', this.stopDrawing);
  },
  beforeUnmount() {
    this.canvas.removeEventListener('mousedown', this.startDrawing);
    this.canvas.removeEventListener('mousemove', this.continueDrawing);
    this.canvas.removeEventListener('mouseup', this.stopDrawing);

    this.canvas.removeEventListener('touchstart', this.startDrawing);
    this.canvas.removeEventListener('touchmove', this.continueDrawing);
    this.canvas.removeEventListener('touchend', this.stopDrawing);
  },
  methods: {
    startDrawing(e) {
      const rect = this.canvas.getBoundingClientRect();

      if (e.type === 'mousedown') {
        this.isDrawing = true;
        [this.lastX, this.lastY] = [e.offsetX, e.offsetY];
      } else if (e.type === 'touchstart') {
        this.isDrawing = true;
        const touch = e.touches[0];
        [this.lastX, this.lastY] = [touch.clientX - rect.left, touch.clientY - rect.top];
      }
    },
    continueDrawing(e) {
      if (!this.isDrawing) return;

      e.preventDefault();

      const rect = this.canvas.getBoundingClientRect();

      if (e.type === 'mousemove') {
        this.draw(e.offsetX, e.offsetY);
      } else if (e.type === 'touchmove') {
        const touch = e.touches[0];
        this.draw(touch.clientX - rect.left, touch.clientY - rect.top);
      }
    },
    draw(x, y) {
      this.ctx.beginPath();
      this.ctx.moveTo(this.lastX, this.lastY);
      this.ctx.lineTo(x, y);
      this.ctx.stroke();

      [this.lastX, this.lastY] = [x, y];
    },
    stopDrawing() {
      this.isDrawing = false;
    },
    clearCanvas() {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    },
  },
};
</script>

<style scoped>
canvas {
  border: 1px solid #000;
}
</style>
