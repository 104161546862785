<template>
  <v-footer
    v-if="!user.isManager"
    app
  >
    <v-row>
      <v-col
        class="d-flex align-center justify-center"
      >
        <v-chip
          class="ma-2"
          color="blue lighten-2"
          text-color="white"
          @click="onReadyClicked"
        >
          <StatusIndicator
            :status="isReadyForCalls ? 'positive' : 'negative'"
            class="mr-1"
          />
          {{ isReadyForCalls ? 'Ready' : 'Not Ready' }}
        </v-chip>
      </v-col>
      <v-col
        cols="3"
      >
        <Dialer />
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { StatusIndicator } from 'vue-status-indicator';
import userMixin from '@/components/mixins/userMixin';
import Dialer from './Dialer.vue';
import TWILIO_STATUS from '../shared/constants/twilio/twilioStatus';

export default {
  components: {
    Dialer,
    StatusIndicator,
  },
  mixins: [userMixin],
  data() {
    return {
      device: null,
    };
  },
  computed: {
    isReadyForCalls() {
      return this.$store.state.twilio.status === TWILIO_STATUS.READY;
    },
  },
  methods: {
    onReadyClicked() {
      if (!this.isReadyForCalls) {
        this.initializeTwilio();
      } else {
        this.$store.dispatch('destroy_device');
      }
    },
    initializeTwilio() {
      this.$http.get('/api/v1/twilio/token')
        .then((res) => {
          this.$store.dispatch('setup_device', {
            token: res.data,
            options: {
              codecPreferences: ['opus', 'pcmu'],
              fakeLocalDTMF: true,
              enableRingingState: false,
            },
          });

          // const logger = getLogger(Device.packageName);
          // logger.setLevel('trace', true);
        })
        .catch((err) => {
          console.log(err);
          console.log('Could not fetch token, see console.log');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
footer {
  z-index: 10;
}
</style>
