import axios from 'axios';
import { HttpHelper } from '../helpers/httpHelper';

export async function getLocationInfo(address) {
  const endpoint = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyCwjx2P15c-n74-1-DQbdsAM5oB8I-vRS0`;
  const response = await axios.get(endpoint);
  return response.data;
}

export async function getCustomerByPhoneNumber(phoneNumber) {
  const response = await HttpHelper.get(`/api/v1/customers/get_by_phone_number/${phoneNumber}`);
  return response.data;
}
