<template>
  <div>
    <v-card>
      <v-card-title>
        Your Tickets
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-row
          class="d-flex justify-content-space-around">
          <v-col
            cols="2">
            <date-picker
              v-model="startDate"
              label="Start Date"
              outlined />
          </v-col>
          <v-col
            cols="2">
            <date-picker
              v-model="endDate"
              label="End Date"
              outlined />
          </v-col>
          <v-col
            cols="4"
            offset="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
          />
          </v-col>

        </v-row>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="filteredTickets"
        :search="search"
        sort-by="createdAt"
        :sort-desc="true"
      >
      <template v-slot:item.category="{ item }">
        <v-chip>
          {{ categoryText(item.category) }}
        </v-chip>
      </template>
      <template v-slot:item.createdAt="{ item }">
      {{ getFormattedDate(item.createdAt) }}
    </template>
      <template v-slot:item.status="{ item }">
        <v-chip>
          {{ statusText(item.status) }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="viewTicket(item)"
        >
          mdi-eye
        </v-icon>
        <!-- <v-icon
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon> -->
      </template>
      <template v-slot:footer
        v-if="totalInteractions">
        <div id="total-interactions" style="position: absolute" class="pt-3 pl-4">
          Total Calls: {{ totalInteractions.totalCalls }} <br />
          Total Messages: {{ totalInteractions.totalMessages }}
        </div>
      </template>
    </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { TICKET_CATEGORIES, TICKET_STATUSES } from '@/shared/constants/ticketConstants';
import dayjs, { formatShortFullDate } from '@/shared/helpers/dayJsHelper';
import DatePicker from '@/components/elements/DatePicker.vue';
import { getTicketsForCurrentUser, getTotalInteractions } from '../shared/apis/ticketApi';

export default {
  name: 'assigned-tickets',
  components: {
    DatePicker,
  },
  props: {
    customerId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Category', value: 'category' },
        { text: 'Customer', value: 'customerName' },
        { text: 'Address', value: 'customerAddress' },
        { text: 'Phone Number', value: 'customerPhoneNumber' },
        { text: 'Description', value: 'description' },
        { text: 'Created Date', value: 'createdAt' },
        { text: 'Status', value: 'status' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      tickets: [],
      startDate: dayjs('2021-01-01').utc().format('YYYY-MM-DD'),
      endDate: dayjs().utc().format('YYYY-MM-DD'),
      totalInteractions: null,
    };
  },
  watch: {
    async startDate() {
      this.totalInteractions = await getTotalInteractions(this.startDate, this.endDate);
    },
    async endDate() {
      this.totalInteractions = await getTotalInteractions(this.startDate, this.endDate);
    },
  },
  computed: {
    startDateText() {
      return this.startDate;
    },
    filteredTickets() {
      const start = dayjs(this.startDate).utc(true).startOf('day');
      const end = dayjs(this.endDate).utc(true).endOf('day');
      return this.tickets.filter((x) => dayjs(x.createdAt).utc().isBetween(start, end));
    },
  },
  async created() {
    this.tickets = await getTicketsForCurrentUser();
    this.totalInteractions = await getTotalInteractions(this.startDate, this.endDate);
  },
  methods: {
    viewTicket(item) {
      this.$router.push({
        name: 'edit_ticket',
        params: {
          ticketId: item.id,
          customerId: item.customerId,
        },
      });
    },
    categoryText(categoryId) {
      return TICKET_CATEGORIES.find((x) => x.value === categoryId).text;
    },
    statusText(statusId) {
      return TICKET_STATUSES.find((x) => x.value === statusId).text;
    },
    getFormattedDate(date) {
      return formatShortFullDate(date);
    },
  },
};
</script>

<style lang="scss" scoped>
#total-interactions {
  font-size: .65em;
  font-weight: bold;
}
</style>
