import { HttpHelper } from '../helpers/httpHelper';

export async function getCustomers() {
  const response = await HttpHelper.get('/api/v1/customers');
  return response.data;
}

export async function getCustomerByPhoneNumber(phoneNumber) {
  const response = await HttpHelper.get(`/api/v1/customers/get_by_phone_number/${phoneNumber}`);
  return response.data;
}
export async function getCustomerById(id) {
  const response = await HttpHelper.get(`/api/v1/customers/${id}`);
  return response.data;
}
export async function createCustomer(data) {
  const response = await HttpHelper.post('/api/v1/customers', data);
  return response.data;
}

export async function updateCustomer(id, data) {
  const response = await HttpHelper.put(`/api/v1/customers/${id}`, data);
  return response.data;
}

export async function deleteCustomer(id) {
  const response = await HttpHelper.delete(`/api/v1/customers/${id}`);
  return response.data;
}

export async function getCustomerSignupsTickets(phoneNumber) {
  const response = await HttpHelper.get(`/api/v1/customers/get_signups_tickets/${phoneNumber}`);
  return response.data;
}
