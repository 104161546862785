import dayjs from 'dayjs';
import AdvancedFormat from 'dayjs/plugin/advancedFormat';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import { SHORT_DAY_MONTH_YEAR_TIME_MERIDIEM } from '@/shared/constants/dateConstants';

dayjs.extend(AdvancedFormat);
dayjs.extend(isBetween);
dayjs.extend(utc);
// eslint-disable-next-line import/prefer-default-export
export default dayjs;

export function formatDate(date, format) {
  return dayjs(date).format(format);
}

export function formatShortFullDate(date) {
  return formatDate(date, SHORT_DAY_MONTH_YEAR_TIME_MERIDIEM);
}
