import { HttpHelper } from '../helpers/httpHelper';

export async function getSignup(id) {
  const response = await HttpHelper.get(`/api/v1/signups/${id}`);
  return response.data;
}

export async function createSignup(data) {
  const response = await HttpHelper.post('/api/v1/signups', data);
  return response.data;
}

export async function updateSignup(id, data) {
  const response = await HttpHelper.put(`/api/v1/signups/${id}`, data);
  return response.data;
}

export async function getSignupsForCurrentCompany(withSales) {
  const response = await HttpHelper.get(`/api/v1/signups?with_sales=${withSales}`);
  return response.data;
}
export async function getSignupsByPhoneNumber(phoneNumber) {
  const response = await HttpHelper.get(`/api/v1/signups/get_by_phone_number/${phoneNumber}`);
  return response.data;
}

export async function getGroundDisruptions() {
  const response = await HttpHelper.get('/api/v1/ground_disruptions');
  return response.data;
}

export async function getQuestions() {
  const response = await HttpHelper.get('/api/v1/questions');
  return response.data;
}

export async function getExport(startDate, endDate) {
  const response = await HttpHelper.get(`api/v1/signups/export?startDate=${startDate}&endDate=${endDate}`, { responseType: 'blob' });
  return [response.data, response.headers];
}

export async function linkCallsToSignups() {
  const response = await HttpHelper.get('/api/v1/signups/link_calls');
  return response.data;
}
