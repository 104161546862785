<template>
  <div>
    <Navigation />
    <v-app-bar
      app
      :color="user.isSPL ? '#bf1e2e' : 'primary'"
      dark
    >
      <v-spacer />
      <div class="d-flex flex-column text-right">
        <span class="text-h6">{{ $store.state.user.user.company }}</span>
        <span class="tiny-text pt-0 mt-0">{{ roleName }}</span>
        <span>{{ $store.state.user.user.email }}</span>
      </div>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </div>
</template>

<script>
import userMixin from '@/components/mixins/userMixin';
import Navigation from '@/components/Navigation.vue';
import Footer from '@/components/Footer.vue';
import { ROLES } from '@/shared/constants/roleConstants';

export default {
  components: {
    Navigation,
    Footer,
  },
  mixins: [userMixin],
  data() {
    return {};
  },
  computed: {
    roleName() {
      if (!this.user || !this.user.role) return '';
      return ROLES.find((x) => x.value === this.user.role).text;
    },
  },
  created() {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push('login');
    }
  },
};
</script>

<style lang="scss" scoped>
.tiny-text {
  font-size: 10px;
  line-height: 80%;
}

</style>
