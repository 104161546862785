/* eslint-disable import/prefer-default-export */
import { HttpHelper } from '../helpers/httpHelper';

export async function getUsers() {
  const response = await HttpHelper.get('/api/v1/users/profiles');
  return response.data;
}

export async function getUser(id) {
  const response = await HttpHelper.get(`/api/v1/users/profiles/${id}`);
  return response.data;
}

export async function updateUser(id, data) {
  const response = await HttpHelper.put(`/api/v1/users/profiles/${id}`, data);
  return response.data;
}

export async function getSettings() {
  const response = await HttpHelper.get('/api/v1/users/settings');
  return response.data;
}

export async function updateSettings(data) {
  const response = await HttpHelper.post('/api/v1/users/settings/update', data);
  return response.data;
}
