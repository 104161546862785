import { HttpHelper } from '@/shared/helpers/httpHelper';

export async function getPlans(buildCommunityId) {
  const response = await HttpHelper.get(`/api/v1/plans?build_community_id=${buildCommunityId}`);
  return response.data;
}

export async function getPlan(id) {
  const response = await HttpHelper.get(`/api/v1/plans/${id}`);
  return response.data;
}

export async function createPlan(data) {
  const response = await HttpHelper.post('/api/v1/plans', data);
  return response.data;
}

export async function updatePlan(id, data) {
  const response = await HttpHelper.put(`/api/v1/plans/${id}`, data);
  return response.data;
}

export async function deletePlan(id) {
  const response = await HttpHelper.delete(`/api/v1/plans/${id}`);
  return response.data;
}
