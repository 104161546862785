<template>
  <div id="dialer">
      <div
        v-if="!connectionExists"
        class="phone-number d-flex align-center justify-space-between px-2 py-2"
      >
        <span class="font-weight-bold">{{ displayPhoneNumber }}</span>
        <v-icon @click="backspace">mdi-backspace-outline</v-icon>
      </div>
      <v-divider class="pt-0 mt-0"/>
      <div class="keys px-3">
        <div class="key-row">
          <button class="btn btn-circle btn-default" @click="sendDigit('1')">1</button>
          <button class="btn btn-circle btn-default" @click="sendDigit('2')">2
            <span>A B C</span>
          </button>
          <button class="btn btn-circle btn-default" @click="sendDigit('3')">3
            <span>D E F</span>
          </button>
        </div>
        <div class="key-row">
          <button class="btn btn-circle btn-default" @click="sendDigit('4')">4
            <span>G H I</span>
          </button>
          <button class="btn btn-circle btn-default" @click="sendDigit('5')">5
            <span>J K L</span>
          </button>
          <button class="btn btn-circle btn-default" @click="sendDigit('6')">6
            <span>M N O</span>
          </button>
        </div>
        <div class="key-row">
          <button class="btn btn-circle btn-default" @click="sendDigit('7')">7
            <span>P Q R S</span>
          </button>
          <button class="btn btn-circle btn-default" @click="sendDigit('8')">8
            <span>T U V</span>
          </button>
          <button class="btn btn-circle btn-default" @click="sendDigit('9')">9
            <span>W X Y Z</span>
          </button>
        </div>
        <div class="key-row">
          <button class="btn btn-circle btn-default" @click="sendDigit('*')">*</button>
          <button class="btn btn-circle btn-default" @click="sendDigit('0')">0</button>
          <button class="btn btn-circle btn-default" @click="sendDigit('#')">#</button>
        </div>
      </div>
  </div>
</template>

<script>
import { error } from '@/shared/helpers/notificationHelper';

export default {
  name: 'phone-input',
  data() {
    return {
      phoneNumber: '',
    };
  },
  computed: {
    displayPhoneNumber() {
      return this.phoneNumber;
    },
    connectionExists() {
      return this.$store.state.twilio.connection != null;
    },
  },
  watch: {
    phoneNumber(val) {
      if (val.length === 11) {
        this.dialNumber(val);
      }
    },
  },
  created() {},
  methods: {
    // handle numeric buttons
    sendDigit(digit) {
      if (this.connectionExists) {
        this.$store.state.twilio.connection.sendDigits(digit);
      } else {
        this.phoneNumber += digit;
      }
    },
    dialNumber(phoneNumber) {
      const params = { To: phoneNumber };
      try {
        this.$store.commit('create_connection', params);
      } catch (e) {
        error("Click the 'Not Ready' button to ready up for calls.");
      }
    },
    backspace() {
      this.phoneNumber = this.phoneNumber.slice(0, -1);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn:focus {
  outline: none;
}
.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}
.btn-default:focus,
.btn-default.focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}
.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-default:active:hover,
.btn-default.active:hover,
.open > .dropdown-toggle.btn-default:hover,
.btn-default:active:focus,
.btn-default.active:focus,
.open > .dropdown-toggle.btn-default:focus,
.btn-default:active.focus,
.btn-default.active.focus,
.open > .dropdown-toggle.btn-default.focus {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
  background-image: none;
}
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus {
  background-color: #fff;
  border-color: #ccc;
}
.btn-default .badge {
  color: #fff;
  background-color: #333;
}

.dropdown-menu li a {
  padding: 3px 10px;
}

.controls, .keys {
  padding-top: 10px;
}

.controls {
  float: left;
}

.controls .btn {
  display: block;
  margin-bottom: 20px;
}

.keys {
  text-align: center;
  color: #fff;
}

.key-row {
  margin-bottom: 20px;
}

.key-row .btn {
  font-weight: 100;
}

.key-row .btn span {
  display: block;
  padding-top: 2px;
  font-size: 10px;
  color: #787878;
}

.log {
  background-color: #ddd;
  clear: both;
  padding: 20px 10px;
  text-align: center;
  font-size: 12px;
  color: #787878;
}

#dialer {
  font-family: Helvetica, sans-serif;
  background-color: white;
  text-align: center;
}

.btn-circle {
  width: 49px;
  height: 49px;
  text-align: center;
  padding: 5px 0;
  font-size: 20px;
  line-height: 2.00;
  border-radius: 30px;
}
</style>
