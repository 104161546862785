var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" Your Tickets "),_c('v-spacer')],1),_c('v-card-text',[_c('v-row',{staticClass:"d-flex justify-content-space-around"},[_c('v-col',{attrs:{"cols":"2"}},[_c('date-picker',{attrs:{"label":"Start Date","outlined":""},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('date-picker',{attrs:{"label":"End Date","outlined":""},model:{value:(_vm.endDate),callback:function ($$v) {_vm.endDate=$$v},expression:"endDate"}})],1),_c('v-col',{attrs:{"cols":"4","offset":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredTickets,"search":_vm.search,"sort-by":"createdAt","sort-desc":true},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(" "+_vm._s(_vm.categoryText(item.category))+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.createdAt))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(" "+_vm._s(_vm.statusText(item.status))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.viewTicket(item)}}},[_vm._v(" mdi-eye ")])]}},(_vm.totalInteractions)?{key:"footer",fn:function(){return [_c('div',{staticClass:"pt-3 pl-4",staticStyle:{"position":"absolute"},attrs:{"id":"total-interactions"}},[_vm._v(" Total Calls: "+_vm._s(_vm.totalInteractions.totalCalls)+" "),_c('br'),_vm._v(" Total Messages: "+_vm._s(_vm.totalInteractions.totalMessages)+" ")])]},proxy:true}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }