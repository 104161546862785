import axios from 'axios';
import store from '../../store';

// eslint-disable-next-line import/prefer-default-export
export const HttpHelper = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: { Authorization: localStorage.getItem('token') ? localStorage.getItem('token') : null },
});

HttpHelper.interceptors.response.use((response) => {
  if (response.status === 401) {
    // add your code
    console.log('Response: 401 Not Authorized');
    store.dispatch('logout');
  }
  return response;
}, (error) => {
  if (error.response.status === 401) {
    // store.dispatch('logout');
  }
  if (error.response && error.response.data) {
    let errorString;

    if (Array.isArray(error.response.data.errors)) {
      // If errors is an array, join the strings
      errorString = error.response.data.errors.join(', ');
    } else if (typeof error.response.data.errors === 'string') {
      // If errors is a single string, use it directly
      errorString = error.response.data.errors;
    } else {
      // Fallback if errors is neither an array nor a string
      errorString = 'An unknown error occurred';
    }

    store.commit('showMessage', { content: errorString, color: 'error' });
    return Promise.reject(error.response.data.errors);
  }
  return Promise.reject(error.message);
});
