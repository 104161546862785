<template>
  <v-card>
    <v-card-title>
      Users
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="users"
      :search="search"
      sort-by="id"
    >
      <template v-slot:top>
        <div class="d-flex align-center">
          <!-- <v-btn
            class="ml-4"
            color="success"
            @click="$router.push(`/customer/0`)">
            Add customer
          </v-btn> -->
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="pb-3"
          />
        </div>

      </template>
      <template v-slot:item.role="{ item }">
        <v-chip>
          {{ roleText(item.role) }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="$router.push(`/user/${item.id}`)"
        >
          mdi-eye
        </v-icon>
        <!-- <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon> -->
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { getUsers } from '@/shared/apis/userApi';
import { ROLES } from '@/shared/constants/roleConstants';

export default {
  name: 'users',
  data() {
    return {
      search: '',
      headers: [
        { text: 'Id', value: 'id' },
        { text: 'Email', value: 'email' },
        { text: 'Company', value: 'company' },
        { text: 'Role', value: 'role' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      users: [],
      roles: ROLES,
    };
  },
  async created() {
    this.users = await getUsers();
  },
  methods: {
    roleText(roleId) {
      const role = ROLES.find((x) => x.value === roleId);
      if (role) return role.text;
      return 'No Assigned Role';
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
