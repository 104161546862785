import { HttpHelper } from '../helpers/httpHelper';

// eslint-disable-next-line import/prefer-default-export
export async function getBadgeCounts() {
  const response = await HttpHelper.get('/api/v1/dashboard/badge_counts');
  return response.data;
}

export async function getCalls() {
  const response = await HttpHelper.get('/api/v1/dashboard/calls');
  return response.data;
}

export async function getMapAddresses() {
  const response = await HttpHelper.get('/api/v1/dashboard/address_map');
  return response.data;
}
