<template>
  <div>
    <v-card>
      <v-card-title>
        Sales
        <v-spacer />
        <!-- <v-btn
          class="ml-4"
          color="primary"
          @click="exportSales()"
        >
          Export Sales <v-icon>mdi-download</v-icon>
        </v-btn> -->
        <v-btn
          class="ml-4"
          color="success"
          @click="$router.push('/saleslanding')"
        >
          Create Sale
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row class="d-flex justify-content-space-around">
          <v-col cols="2">
            <date-picker
              v-model="startDate"
              label="Start Date"
              outlined
            />
          </v-col>
          <v-col cols="2">
            <date-picker
              v-model="endDate"
              label="End Date"
              outlined
            />
          </v-col>
          <v-col
            cols="4"
            offset="4"
          >
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="filteredSignups"
        :search="search"
        sort-by="createdAt"
        :sort-desc="true"
      >
        <template v-slot:item.createdAt="{ item }">
          {{ getFormattedDate(item.createdAt) }}
        </template>
        <template v-slot:item.isApproved="{ item }">
          <v-icon
            v-if="item.isApproved"
            color="success"
          >
            mdi-check
          </v-icon>
          <v-icon
            v-else
            color="error"
          >
            mdi-close
          </v-icon>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="viewSignup(item)"
          >
            mdi-eye
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import dayjs, { formatShortFullDate } from '@/shared/helpers/dayJsHelper';
import DatePicker from '@/components/elements/DatePicker.vue';
import { getSignupsForCurrentCompany } from '@/shared/apis/signupApi';

export default {
  name: 'Sales',
  components: {
    DatePicker,
  },
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Id',
          align: 'start',
          sortable: false,
          value: 'id',
        },
        { text: 'Customer', value: 'customerName' },
        { text: 'Address', value: 'customerAddress' },
        { text: 'Phone Number', value: 'customerPhoneNumber' },
        { text: 'Created Date', value: 'createdAt' },
        { text: 'Approved', value: 'isApproved' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      signups: [],
      startDate: dayjs('2021-01-01').utc().format('YYYY-MM-DD'),
      endDate: dayjs().utc().format('YYYY-MM-DD'),
    };
  },
  computed: {
    filteredSignups() {
      const start = dayjs(this.startDate).utc(true).startOf('day');
      const end = dayjs(this.endDate).utc(true).endOf('day');
      return this.signups.filter((x) => dayjs(x.createdAt).utc().isBetween(start, end));
    },
  },
  async created() {
    try {
      this.signups = await getSignupsForCurrentCompany(true);
    } catch {
      this.$store.dispatch('setSnackbar', {
        text: 'Error getting signups',
        color: 'error',
      });
    }
  },
  methods: {
    viewSignup(item) {
      this.$router.push({
        name: 'edit_signup',
        params: {
          signupId: item.id,
        },
      });
    },
    getFormattedDate(date) {
      return formatShortFullDate(date);
    },
    // async exportSignups() {
    //   const result = await getExport(this.startDate, this.endDate);
    //   const aElement = document.createElement('a');
    //   aElement.setAttribute('download', result[1]['content-disposition']);
    //   const href = URL.createObjectURL(new Blob([result[0]]));
    //   aElement.href = href;
    //   // aElement.setAttribute('href', href);
    //   aElement.setAttribute('target', '_blank');
    //   aElement.click();
    //   URL.revokeObjectURL(href);
    // },
  },
};
</script>

<style lang="scss" scoped>
</style>
