<template>
  <v-container
    v-if="badgeCounts"
    fluid
  >
    <div />
    <div v-if="!user.isManager">
      <v-row>
        <v-col cols="4">
          <InfoCard
            title="Tickets Created"
            :count="badgeCounts.ticketCount"
            color="green"
          />
        </v-col>
        <v-col cols="4">
          <InfoCard
            title="Open Tickets"
            :count="badgeCounts.openTicketCount"
            color="blue"
          />
        </v-col>
        <v-col cols="4">
          <InfoCard
            title="Missed Calls"
            :count="badgeCounts.missedCallCount"
            color="red"
          />
        </v-col>
      </v-row>
      <DashboardMap />
      <CallLog
        v-if="calls"
        :calls="calls"
        :headers="callHeaders"
        class="mb-5"
        @reload="this.loadCalls"
      >
        <template v-slot:footer>
          <div
            id="total-interactions"
            style="position: absolute"
            class="pt-3 pl-4"
          >
            Total Incoming Calls: {{ badgeCounts.incomingCallCount }} <br>
            Total Outgoing Calls: {{ badgeCounts.outgoingCallCount }} <br>
          </div>
        </template>
      </CallLog>
      <!-- <v-row>
        <v-col cols="">
        </v-col>
        <v-col cols="6">

        </v-col>
      </v-row> -->
      <!-- <v-row>
        <v-col cols="4">
          <InfoCard title="Total Monthly Calls" :count="badgeCounts.monthlyCallCount" color="red" />
        </v-col>
        <v-col cols="4">
          <InfoCard title="Total Monthly Messages" :count="3" color="green" />
        </v-col>
        <v-col cols="4">
          <InfoCard title="Total Monthly Emails" :count="16" color="purple" />
        </v-col>
      </v-row> -->
    </div>
  </v-container>
</template>

<script>
// @ is an alias to /src

import InfoCard from '@/components/InfoCard.vue';
import DashboardMap from '@/components/maps/DashboardMap.vue';
import { getBadgeCounts, getCalls } from '@/shared/apis/dashboardApi';
import userMixin from '@/components/mixins/userMixin';
import CallLog from '@/components/CallLog.vue';

export default {
  name: 'Dashboard',
  components: {
    InfoCard,
    DashboardMap,
    CallLog,
  },
  mixins: [userMixin],
  data() {
    return {
      badgeCounts: null,
      calls: null,
    };
  },
  computed: {
    callHeaders() {
      return [
        {
          text: 'Date',
          value: 'createdAt',
        },
        {
          text: 'Customer Name',
          value: 'customerName',
        },
        { text: 'From', value: 'from' },
        { text: 'To', value: 'to' },
        { text: 'Call Direction', value: 'callType' },
        { text: 'Status', value: 'status' },
        { text: 'Recording', value: 'recordingUrl', sortable: false },
        { text: 'Actions', value: 'actions', align: 'right' },
      ];
    },
  },
  async created() {
    this.badgeCounts = await getBadgeCounts();
    this.loadCalls();
    if (this.user.isManager) {
      this.$router.push('/tickets');
    }
  },
  methods: {
    async loadCalls() {
      this.calls = await getCalls();
    },
  },
};
</script>
<style lang="scss">
.map-container {
  height: 800px;
}
.full-height {
  height: 100%;
}
</style>
