export const TICKET_STATUS_LOOKUP = {
  AWAITING_UPDATE: 0,
  REVOKE_LIST: 1,
  SIGN_UP_LIST: 2,
  REOPENED: 3,
  REPORTED_TO_SUPERVISOR: 4,
  RESOLVED_BY_FIELD_MANAGER: 5,
  RESOLVED_BY_CUSTOMER_CARE: 6,
  AUXILIARY_NO_STATUS: 7,
};

export const TICKET_STATUSES = [
  {
    text: 'Awaiting Update',
    value: TICKET_STATUS_LOOKUP.AWAITING_UPDATE,
  },
  {
    text: 'Revoke List',
    value: TICKET_STATUS_LOOKUP.REVOKE_LIST,
  },
  {
    text: 'Sign up List',
    value: TICKET_STATUS_LOOKUP.SIGN_UP_LIST,
  },
  {
    text: 'Reopened',
    value: TICKET_STATUS_LOOKUP.REOPENED,
  },
  {
    text: 'Reported to Supervisor',
    value: TICKET_STATUS_LOOKUP.REPORTED_TO_SUPERVISOR,
  },
  {
    text: 'Resolved by Field Manager',
    value: TICKET_STATUS_LOOKUP.RESOLVED_BY_FIELD_MANAGER,
  },
  {
    text: 'Resolved by Customer Care',
    value: TICKET_STATUS_LOOKUP.RESOLVED_BY_CUSTOMER_CARE,
  },
  {
    text: 'Auxiliary - No Status',
    value: TICKET_STATUS_LOOKUP.AUXILIARY_NO_STATUS,
  },
];

export const TICKET_CATEGORY_LOOKUP = {
  COMPLAINT: 0,
  CONCERN: 1,
  DAMAGE_TO_PROPERTY: 2,
  DAMAGE_TO_SERVICE: 3,
  PRE_FIELD_INFORMATION: 4,
  PUBLIC_INJURY: 5,
  PUBLIC_SAFETY: 6,
  QUESTION: 7,
  REPORTED_REVOKE: 8,
  REVOKE: 9,
  AUXILIARY: 10,
};

export const TICKET_CATEGORIES = [
  {
    text: 'Complaint',
    value: TICKET_CATEGORY_LOOKUP.COMPLAINT,
  },
  {
    text: 'Concern',
    value: TICKET_CATEGORY_LOOKUP.CONCERN,
  },
  {
    text: 'Damage to Property',
    value: TICKET_CATEGORY_LOOKUP.DAMAGE_TO_PROPERTY,
  },
  {
    text: 'Damage to Service',
    value: TICKET_CATEGORY_LOOKUP.DAMAGE_TO_SERVICE,
  },
  {
    text: 'Pre Field Information',
    value: TICKET_CATEGORY_LOOKUP.PRE_FIELD_INFORMATION,
  },
  {
    text: 'Public Injury',
    value: TICKET_CATEGORY_LOOKUP.PUBLIC_INJURY,
  },
  {
    text: 'Public Safety',
    value: TICKET_CATEGORY_LOOKUP.PUBLIC_SAFETY,
  },
  {
    text: 'Question',
    value: TICKET_CATEGORY_LOOKUP.QUESTION,
  },
  {
    text: 'Reported Revoke',
    value: TICKET_CATEGORY_LOOKUP.REPORTED_REVOKE,
  },
  {
    text: 'Revoke',
    value: TICKET_CATEGORY_LOOKUP.REVOKE,
  },
  {
    text: 'Auxiliary Call',
    value: TICKET_CATEGORY_LOOKUP.AUXILIARY,
  },
];
