<template>
  <v-card>
    <v-card-title>
      Companies
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="companies"
      :search="search"
    >
      <template v-slot:top>
        <div class="d-flex align-center">
          <v-btn
            class="ml-4"
            color="success"
            @click="$router.push('/company/0')">
            Add company
          </v-btn>
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="pb-3"
          />
        </div>

      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="$router.push(`/company/${item.id}`)"
        >
          mdi-eye
        </v-icon>
        <!-- <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon> -->
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { getCompanies } from '../shared/apis/companyApi';

export default {
  data() {
    return {
      search: '',
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        { text: 'Phoner Number', value: 'phoneNumber' },
        { text: 'Message Number', value: 'messageNumber' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      companies: [],
    };
  },
  async created() {
    this.companies = await getCompanies();
  },
};
</script>
<style lang="scss" scoped>

</style>
