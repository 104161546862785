// export const ROLE_LOOKUP = {
//   VIEWER: 'viewer',
//   SUPER_ADMIN: 'super_admin',
//   SENIOR_MANAGER: 'senior_manager',
//   MANAGER: 'manager',
//   AGENT: 'agent',
// };

export const ROLE_LOOKUP = {
  VIEWER: 0,
  SUPER_ADMIN: 1,
  SENIOR_MANAGER: 2,
  MANAGER: 3,
  AGENT: 4,
};

export const ROLES = [
  {
    text: 'Viewer',
    value: ROLE_LOOKUP.VIEWER,
  },
  {
    text: 'Super Administrator',
    value: ROLE_LOOKUP.SUPER_ADMIN,
  },
  {
    text: 'Senior Manager',
    value: ROLE_LOOKUP.SENIOR_MANAGER,
  },
  {
    text: 'Manager',
    value: ROLE_LOOKUP.MANAGER,
  },
  {
    text: 'Agent',
    value: ROLE_LOOKUP.AGENT,
  },
];
