import Vue from 'vue';
import Vuex from 'vuex';
import userStore from './userStore';
import twilioStore from './twilioStore';
import snackbarStore from './snackbarStore';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user: userStore,
    twilio: twilioStore,
    snackbar: snackbarStore,
  },
});
