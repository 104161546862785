import { HttpHelper } from '../helpers/httpHelper';

export async function getCalls() {
  const response = await HttpHelper.get('/api/v1/calls');
  return response.data;
}

export async function updateCall(id, data) {
  const response = await HttpHelper.put(`/api/v1/calls/${id}`, data);
  return response.data;
}

export async function getCallsBySignupId(signupId) {
  const response = await HttpHelper.get(`/api/v1/calls/get_by_param?signup_id=${signupId}`);
  return response.data;
}
