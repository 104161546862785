var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Call Log "),_c('v-spacer'),(!_vm.noSearch)?_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.displayHeaders,"items":_vm.calls,"search":_vm.search,"sort-by":"createdAt","sort-desc":true},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getFormattedDate(item.createdAt))+" ")]}},{key:"item.callType",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(" "+_vm._s(item.callType)+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.recordingUrl",fn:function(ref){
var item = ref.item;
return [(item.recordingUrl)?_c('audio',{key:item.recordingUrl,attrs:{"controls":""}},[_c('source',{attrs:{"src":item.recordingUrl}}),_vm._v(" Your browser does not support the audio element. ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [((item.ticketId && item.customerId) || item.signupId)?_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.redirectToAction(item)}}},[_vm._v(" mdi-eye ")]):_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.openAttachCallDialog(item)}}},[_vm._v(" mdi-attachment ")])]}}])},[_vm._t("footer",null,{"slot":"footer"})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }