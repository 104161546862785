<template>
  <v-menu
  v-model="menu"
  :close-on-content-click="false"
  max-width="290px"
  >
    <template v-slot:activator="{ on }">
        <v-text-field
          :label="label"
          readonly
          prepend-icon="mdi-calendar"
          :value="dateText"
          v-on="on">
        </v-text-field>
    </template>
    <v-date-picker
      v-model="date"
      @input="menu = false">
    </v-date-picker>
  </v-menu>
</template>

<script>

export default {
  name: 'date-picker',
  props: {
    value: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      date: this.value,
      menu: false,
    };
  },
  computed: {
    dateText() {
      return this.date;
    },
  },
  watch: {
    date() {
      this.$emit('input', this.date);
    },
  },
};
</script>

<style lang="sass" scoped>

</style>
