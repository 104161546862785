<template>
  <div>
    <v-row>
      <v-col
      >
        <div
          class="d-flex justify-space-around align-center"
          v-if="isRinging || isInProgress">
          <v-btn
            color="primary"
            elevation="2"
            fab
            x-small
            class="mr-2"
            @click="$router.push(`/customer/${customer.id}`)"
          >
            <v-icon>
              mdi-account
            </v-icon>
          </v-btn>
          <div
            class="d-flex flex-column"
          >
            <h4>{{ customer.name }}</h4>
            <span>{{ customer.phoneNumber }}</span>
            <span>{{ customer.address }}</span>
          </div>
        </div>

      </v-col>
      <v-col
        cols="4"
        class="d-flex align-center justify-end"
      >
        <v-menu
          v-model="showDialPad"
          top
          nudge-top="75"
          :close-on-content-click="false"
          :close-on-click="false">
          <template v-slot:activator="{ }">
            <v-btn
              :color="isInProgress ? 'error' : 'success'"
              :class="callButtonStyle"
              elevation="2"
              fab
              x-large
              class="mr-2"
              @click="onPhoneClicked"
            >
              <v-icon>
                {{ isInProgress ? 'mdi-phone-hangup' : 'mdi-phone' }}
              </v-icon>
            </v-btn>
          </template>
          <phone-input />
        </v-menu>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TWILIO_STATUS from '../shared/constants/twilio/twilioStatus';
import { getCustomerByPhoneNumber } from '../shared/apis/customerApi';
import PhoneInput from './PhoneInput.vue';

export default {
  components: {
    PhoneInput,
  },
  data() {
    return {
      showDialPad: false,
      customer: {
        id: 0,
        name: 'No Caller ID',
        phoneNumber: 'No Phone Number',
        address: 'No Address Found',
      },
    };
  },
  computed: {
    isRinging() {
      return this.$store.state.twilio.status === TWILIO_STATUS.RINGING;
    },
    isInProgress() {
      return this.$store.state.twilio.status === TWILIO_STATUS.IN_PROGRESS;
    },
    connectionExists() {
      return this.$store.state.twilio.connection != null;
    },
    callButtonStyle() {
      return this.isRinging ? 'ringing' : '';
    },
  },
  watch: {
    async isRinging(newVal) {
      if (newVal && this.connectionExists) {
        const number = this.$store.state.twilio.connection.parameters.From;
        this.customer.phoneNumber = number;
        this.customer = await getCustomerByPhoneNumber(number);
      }
    },
  },
  methods: {
    async onPhoneClicked() {
      if (this.$store.state.twilio.connection) {
        if (this.isInProgress) {
          // hangup
          this.$store.commit('destroy_connection');
          this.showDialPad = false;
        } else {
          this.$store.commit('accept_connection');
          this.showDialPad = true;
        }
      } else {
        this.showDialPad = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ringing {
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;

  /* When the animation is finished, start again */
  animation-iteration-count: infinite;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
</style>
