import { HttpHelper } from '@/shared/helpers/httpHelper';

export async function getBuildCommunities() {
  const response = await HttpHelper.get('/api/v1/build_communities');
  return response.data;
}

export async function getBuildCommunity(id) {
  const response = await HttpHelper.get(`/api/v1/build_communities/${id}`);
  return response.data;
}

export async function createBuildCommunity(data) {
  const response = await HttpHelper.post('/api/v1/build_communities', data);
  return response.data;
}

export async function updateBuildCommunity(id, data) {
  const response = await HttpHelper.put(`/api/v1/build_communities/${id}`, data);
  return response.data;
}

export async function deleteBuildCommunity(id) {
  const response = await HttpHelper.delete(`/api/v1/build_communities/${id}`);
  return response.data;
}
