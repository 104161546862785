import { render, staticRenderFns } from "./Dialer.vue?vue&type=template&id=fd3c88c4&scoped=true&"
import script from "./Dialer.vue?vue&type=script&lang=js&"
export * from "./Dialer.vue?vue&type=script&lang=js&"
import style0 from "./Dialer.vue?vue&type=style&index=0&id=fd3c88c4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd3c88c4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCol,VIcon,VMenu,VRow})
