<template>
    <div>
      <div class="google-map" ref="googleMap" />
      <template v-if="Boolean(this.google) && Boolean(this.map)">
        <slot
          :google="google"
          :map="map"
        />
      </template>
    </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';

export default {
  props: {
    mapConfig: Object,
    apiKey: String,
  },
  data() {
    return {
      google: null,
      map: null,
    };
  },
  mounted() {
    const loader = new Loader({
      apiKey: this.apiKey,
    });
    loader
      .load()
      .then(() => {
        // eslint-disable-next-line no-undef, no-new
        this.google = window.google;
        this.map = new window.google.maps.Map(this.$refs.googleMap, this.mapConfig);
        // this.initializeMap();
      });
  },
  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap;
      this.map = new this.google.maps.Map(
        mapContainer, this.mapConfig,
      );
    },
  },
};

</script>

<style lang="scss" scoped>
  .google-map {
    height: 645px;
  }
</style>
