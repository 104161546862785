<template>
  <v-container fluid>
    <v-layout column>
      <v-card>
        <v-card-title>
          Create Sale {{ planId }}
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="signup.ownerName"
                label="Home / Building Owner Name"
                outlined
                dense
              />
              <v-text-field
                v-model="signup.ownerEmail"
                label="Email"
                outlined
                dense
              />
              <v-text-field
                v-model="signup.ownerPhoneNumber"
                label="Phone Number"
                outlined
                dense
              />
              <v-text-field
                v-model="signup.ownerAlternatePhoneNumber"
                label="Alternate Phone Number"
                outlined
                dense
              />
              <div class="d-flex justify-space-between">
                <v-checkbox
                  v-model="isOwnerOccupied"
                  label="Owner Occupied"
                  class="pt-0 mt-0"
                  outlined
                  dense
                />
              </div>
            </v-col>
            <v-divider vertical />
            <v-col>
              <v-text-field
                v-model="signup.occupantName"
                label="Occupant's Name"
                outlined
                dense
              />
              <v-text-field
                v-model="signup.occupantPhoneNumber"
                label="Occupant's Phone Number"
                outlined
                dense
              />
              <v-text-field
                v-model="signup.occupantAlternatePhoneNumber"
                label="Occupant's Alternate Phone Number"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
            >
              <v-text-field
                v-model="signup.address"
                label="Address"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="signup.city"
                label="City"
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="4"
            >
              <v-select
                v-model="signup.province"
                :items="provinces"
                label="Province"
                outlined
                dense
              />
            </v-col>
            <v-col
              cols="4"
            >
              <v-text-field
                v-model="signup.postalCode"
                label="Postal Code"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-radio-group
                v-model="signup.groundDisruptionId"
                label="Type of service drop required:"
              >
                <v-radio
                  v-for="item in groundDisruptionOptions"
                  :key="item.id"
                  :label="item.text"
                  :value="item.id"
                />
              </v-radio-group>
              <v-text-field
                v-if="isOtherDisruptionSelected"
                v-model="signup.otherGroundDisruptionText"
                dense
                outlined
                label="Other service drop"
              />
            </v-col>
            <v-col
              cols="2"
            >
              <div>
                <v-checkbox
                  v-for="item in questions"
                  :key="item.id"
                  v-model="checkedQuestions"
                  :label="item.text"
                  :value="item.id"
                />
              </div>
            </v-col>
            <v-col
              class="d-flex align-center"
            >
              <p>
                SLA - BE = Best Effort, 1 = Bronze, 2 = Silver, 3 = Gold, 4 = Platinum.
                Please refer to SLA document for details.
                MRC - Monthly Recurring Charge, NRI - Non-Recurring Installation Charge,
                Equipment - One-time charge for equipment
                (routers are not included in the install fee)
                **The installation costs associated with the Service are conditional upon
                the results of a final detailed field facilities analysis.
                Should the analysis determine that the provisioning costs will be greater
                than indicated above, the parties will renegotiate the installation
                and/or monthly recurring charges.  Work inside the premise may be extra.**
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-file-input
                v-model="signup.propertySketch"
                label="Property Sketch"
                outlined
                dense
                chips
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="signup.comment"
                dense
                outlined
                label="Comments"
                :rules="[
                  v => !!v || 'Comment is required',
                  v => v.length >= 25 || 'Comment must be at least 25 characters',
                ]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <h3>Owner Signature</h3>
              <signature-canvas
                ref="signatureCanvas"
              />
            </v-col>
            <v-col
              cols="8"
            >
              <h3>Terms & Conditions</h3>
              The delivery of the Equipment/Services is subject to RFNow Terms and Conditions
              published at www.rfnow.com/terms (the Terms and Conditions).
              In the event of a conflict between this Agreement and the
              Terms and Conditions, this Agreement shall prevail.
              The parties hereto expressly acknowledge and agree that the Terms and Conditions
              are incorporated by reference and shall apply to the Equipment/Services without
              requirement for execution by either party.
              The Customer acknowledges having read and understood this Agree and the Terms
              and Conditions and agrees to be bound according to their terms.
              RFNow Inc. may change the Terms and Conditions at its pleasure by
              publishing updated Terms and Conditions at www.rfnow.com/terms.
              The published revised Terms and Conditions shall be of full
              effect on the thirtieth calendar day following such publication.
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            :loading="loading"
            @click.native="submitSignup"
          >
            Submit Sale
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-layout>
  </v-container>
</template>

<script>
import SignatureCanvas from '@/components/SignatureCanvas.vue';
import { createSignup, getGroundDisruptions, getQuestions } from '../shared/apis/signupApi';

export default {
  name: 'NewSale',
  components: {
    SignatureCanvas,
  },
  props: {
    planId: {
      type: Number,
      default: null,
    },
  },
  pageTitle: 'Sale',
  data() {
    return {
      loading: false,
      signup: {
        ownerName: '',
        ownerPhoneNumber: '',
        ownerAlternatePhoneNumber: '',
        occupantName: '',
        occupantPhoneNumber: '',
        occupantAlternatePhoneNumber: '',
        address: '',
        city: '',
        province: '',
        postalCode: '',
        propertySketch: null,
        groundDisruptionId: null,
        otherGroundDisruptionText: '',
        comment: '',
        isReferredToSales: false,
      },
      isOwnerOccupied: false,
      groundDisruptionOptions: [],
      questions: [],
      checkedQuestions: [],
      provinces: ['AB', 'BC', 'MB', 'NB', 'NL', 'NS', 'NT', 'NU', 'ON', 'PE', 'QC', 'SK', 'YT'],
    };
  },
  computed: {
    userId() {
      return this.$store.state.user.user.id;
    },
    isOtherDisruptionSelected() {
      if (!this.signup.groundDisruptionId) return false;
      const selectedGroundDisruption = this.groundDisruptionOptions
        .find((item) => item.id === this.signup.groundDisruptionId);
      return selectedGroundDisruption.text === 'Other';
    },
  },
  watch: {
    isOwnerOccupied(newVal) {
      if (newVal) {
        // Populate occupant text boxes with owner data
        this.signup.occupantName = this.signup.ownerName;
        this.signup.occupantPhoneNumber = this.signup.ownerPhoneNumber;
        this.signup.occupantAlternatePhoneNumber = this.signup.ownerAlternatePhoneNumber;
      } else {
        // Clear occupant text boxes
        this.signup.occupantName = '';
        this.signup.occupantPhoneNumber = '';
        this.signup.occupantAlternatePhoneNumber = '';
      }
    },
  },
  async created() {
    await this.getGroundDisruptions();
    await this.getQuestions();
  },
  methods: {
    async getGroundDisruptions() {
      this.groundDisruptionOptions = await getGroundDisruptions();
    },
    async getQuestions() {
      this.questions = await getQuestions();
    },
    async submitSignup() {
      const formData = new FormData();
      formData.append('customer[owner_name]', this.signup.ownerName);
      formData.append('customer[owner_email]', this.signup.ownerEmail);
      formData.append('customer[owner_phone_number]', this.signup.ownerPhoneNumber);
      formData.append('customer[owner_alternate_phone_number]', this.signup.ownerAlternatePhoneNumber);
      formData.append('customer[address]', this.signup.address);
      formData.append('customer[city]', this.signup.city);
      formData.append('customer[province]', this.signup.province);
      formData.append('customer[postal_code]', this.signup.postalCode);

      formData.append('sale[plan_id]', this.planId);
      formData.append('sale[sale_questions]', this.checkedQuestions);

      formData.append('sale[created_by_id]', this.userId);
      formData.append('signup[occupant_name]', this.signup.occupantName);
      formData.append('signup[occupant_phone_number]', this.signup.occupantPhoneNumber);
      formData.append('signup[occupant_alternate_phone_number]', this.signup.occupantAlternatePhoneNumber);
      formData.append('signup[ground_disruption_id]', this.signup.groundDisruptionId);
      formData.append('signup[other_ground_disruption_text]', this.signup.otherGroundDisruptionText);
      formData.append('signup[comment]', this.signup.comment);
      formData.append('signup[created_by_id]', this.userId);

      const { canvas } = this.$refs.signatureCanvas.$refs;

      if (!this.isCanvasBlank(canvas)) {
        const dataUrl = canvas.toDataURL();
        const blob = this.dataURLToBlob(dataUrl);
        const file = new File([blob], 'signature.png', { type: 'image/png' });
        formData.append('signup[signature]', file);
      }

      if (this.signup.propertySketch) {
        formData.append('signup[property_sketch]', this.signup.propertySketch);
      }

      const result = await createSignup(formData);
      const content = 'Sale Created.';
      const color = 'success';
      this.$store.commit('showMessage', { content, color });
      this.$router.push({
        name: 'edit_signup',
        params: {
          signupId: result.id,
        },
      });
    },
    isCanvasBlank(canvas) {
      const context = canvas.getContext('2d');
      const pixelData = context.getImageData(0, 0, canvas.width, canvas.height).data;

      for (let i = 0; i < pixelData.length; i += 4) {
        const alpha = pixelData[i + 3];
        if (alpha !== 0) {
          return false; // Canvas is not blank
        }
      }

      return true; // Canvas is blank
    },
    dataURLToBlob(dataURL) {
      const arr = dataURL.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      const n = bstr.length;
      const u8arr = new Uint8Array(n);

      let i = 0;
      while (i < n) {
        u8arr[i] = bstr.charCodeAt(i);
        i += 1;
      }

      return new Blob([u8arr], { type: mime });
    },
  },
};
</script>

  <style lang="scss" scoped>

  </style>
