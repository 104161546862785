import axios from 'axios';

export default {
  state: () => ({
    status: '',
    token: localStorage.getItem('token') || '',
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
  }),
  mutations: {
    auth_request(state) {
      state.status = 'loading';
    },
    auth_success(state, data) {
      state.status = 'success';
      state.token = data.token;
      state.user = data.user;
    },
    auth_error(state) {
      state.status = 'error';
    },
    logout(state) {
      state.status = '';
      state.token = '';
      state.user = {};
    },
  },
  actions: {
    register({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        axios({
          url: `${process.env.VUE_APP_API_BASE_URL}/signup`,
          data: user,
          method: 'POST',
        })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            commit('auth_error', err);
            localStorage.removeItem('token');
            reject(err);
          });
      });
    },
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');
        axios({
          url: `${process.env.VUE_APP_API_BASE_URL}/login`,
          data: { user },
          method: 'POST',
        })
          .then((resp) => {
            const token = resp.headers.authorization;
            const dbUser = resp.data;
            localStorage.setItem('token', token);
            localStorage.setItem('user', JSON.stringify(dbUser));

            axios.defaults.headers.common.Authorization = token;
            commit('auth_success', { token, user: dbUser });
            resolve(resp);
          })
          .catch((err) => {
            commit('auth_error');
            localStorage.removeItem('token');
            reject(err);
          });
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        const config = {
          headers: {
            contentType: 'application/json',
            authorization: localStorage.getItem('token'),
          },
        };

        axios.delete(`${process.env.VUE_APP_API_BASE_URL}/logout`, null, config)
          .then(() => {
            commit('logout');
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            delete axios.defaults.headers.common.Authorization;
            resolve();
          })
          .catch((err) => {
            console.log(err);
            commit('auth_error');
            reject(err);
          });
      });
    },
  },
  getters: {
    isLoggedIn: (state) => {
      const doesTokenExist = !!state.token && state.token !== '';
      const doesUserIdExist = state.user !== undefined && state.user.id > 0;
      return doesTokenExist && doesUserIdExist;
    },
    authStatus: (state) => state.status,
  },
};
