<template>
  <v-navigation-drawer
    app
    permanent
  >
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          <v-img
            v-if="user.isSPL"
            src="../assets/spl_innovations.jpg"
          />
          <v-img
            v-if="user.isRFNow"
            src="../assets/rfnow-logo.png"
          />
          <v-img
            v-else
            src="../assets/smithco_telecom.png"
          />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider />

    <v-list
      dense
      nav
    >
      <v-list-item
        v-for="item in navigationItems"
        :key="item.title"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title
            @click="navigationItemClicked(item)"
          >
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <template v-slot:append>
      <v-divider />
      <div class="powered-by-spl mx-4 mb-5">
        <span class="text-overline">powered by</span>
        <v-img
          src="../assets/spl_innovations.jpg"
          contain
          height="70"
          position="left"
        />
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import userMixin from '@/components/mixins/userMixin';

export default {
  mixins: [userMixin],
  data() {
    return {
      items: [],
      right: null,
    };
  },
  computed: {
    navigationItems() {
      let items = [];
      if (this.user.isLoggedIn) {
        items.push(
          { title: 'Dashboard', icon: 'mdi-view-dashboard', url: '/' },
          { title: 'Tickets', icon: 'mdi-receipt', url: '/tickets' },
          { title: 'Signups', icon: 'mdi-pencil', url: '/signups' },
          { title: 'Sales', icon: 'mdi-sale', url: '/sales' },
        );

        if (!this.user.isManager) {
          items.push(
            { title: 'Directory', icon: 'mdi-book-open-page-variant', url: '/directory' },
          );
        }

        if (this.user.isSuperAdmin) {
          items.push(
            { title: 'Users', icon: 'mdi-account-circle', url: '/users' },
            { title: 'Companies', icon: 'mdi-briefcase', url: '/companies' },
          );
        }

        items.push({ title: 'Policies', icon: 'mdi-file-document', url: '/policies' });

        if (!this.user.isManager) {
          items.push({ title: 'Settings', icon: 'mdi-cog', url: '/settings' });
        }
        items.push({ title: 'Logout', icon: 'mdi-account-circle-outline', url: '/logout' });
      } else {
        items = [
          { title: 'Login', icon: 'mdi-account-circle', url: '/login' },
        ];
      }
      return items;
    },
  },
  created() {
  },
  methods: {
    navigationItemClicked(item) {
      if (item.url) {
        this.$router.push(item.url);
      }
    },
    logout() {
      this.$store.dispatch('logout')
        .then(() => this.$router.push('/login'))
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
