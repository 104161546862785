<script>
import { POINT_MARKER_ICON_CONFIG } from '@/shared/constants/mapSettings';

export default {
  props: {
    google: {
      type: Object,
      required: true,
    },
    map: {
      type: Object,
      required: true,
    },
    marker: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    // eslint-disable-next-line no-new
    const marker = new this.google.maps.Marker({
      position: this.marker.position,
      marker: this.marker,
      map: this.map,
      icon: POINT_MARKER_ICON_CONFIG,
    });

    marker.addListener('click', (e) => this.$emit('click', e));
    marker.addListener('mouseover', (e) => this.$emit('mouseover', e));
  },
  render() { return null; },
};
</script>
