<template>
  <div />
</template>

<script>
export default {
  data() {
    return {
      snackbar: true,
    };
  },
  mounted() {
    this.logout();
  },
  methods: {
    logout() {
      const content = 'Logged out successfully.';
      const color = 'green';
      this.$store.dispatch('logout')
        .then(() => this.$store.commit('showMessage', { content, color }))
        .then(() => this.$router.push('/login'))
        .catch((err) => console.log(err));
    },
  },
};
</script>
