import { ROLE_LOOKUP } from '@/shared/constants/roleConstants';

const splCompanies = ['spl', 'telus', 'feastify'];

export default {
  computed: {
    user() {
      return {
        isSPL: splCompanies.some((x) => this.$store.state.user.user.company
          .toLowerCase()
          .includes(x)),
        isRFNow: this.$store.state.user.user.company.toLowerCase() === 'rfnow',
        isManager: this.$store.state.user.user.role === ROLE_LOOKUP.MANAGER,
        isSuperAdmin: this.$store.state.user.user.role === ROLE_LOOKUP.SUPER_ADMIN,
        isAgent: this.$store.state.user.user.role === ROLE_LOOKUP.AGENT,
        isLoggedIn: this.$store.getters.isLoggedIn,
        role: this.$store.state.user.user.role,
      };
    },
  },
};
