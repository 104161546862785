import Vue from 'vue';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import * as ModalDialogs from 'vue-modal-dialogs';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { HttpHelper } from './shared/helpers/httpHelper';
import 'tiptap-vuetify/dist/main.css';

Vue.config.productionTip = false;
Vue.prototype.$http = HttpHelper;

Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi',
});

Vue.use(ModalDialogs);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});
