<template>
  <v-card>
    <v-card-title>
      Call Log
      <v-spacer />
      <v-text-field
        v-if="!noSearch"
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      />
    </v-card-title>
    <v-data-table
      :headers="displayHeaders"
      :items="calls"
      :search="search"
      sort-by="createdAt"
      :sort-desc="true"
    >
      <!-- Date-->
      <template v-slot:item.createdAt="{ item }">
        {{ getFormattedDate(item.createdAt) }}
      </template>
      <template v-slot:item.callType="{ item }">
        <v-chip>
          {{ item.callType }}
        </v-chip>
      </template>
      <template v-slot:item.status="{ item }">
        <v-chip>
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:item.recordingUrl="{ item }">
        <audio
          v-if="item.recordingUrl"
          :key="item.recordingUrl"
          controls
        >
          <source :src="item.recordingUrl">
          Your browser does not support the audio element.
        </audio>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="(item.ticketId && item.customerId) || item.signupId"
          class="mr-2"
          @click="redirectToAction(item)"
        >
          mdi-eye
        </v-icon>
        <v-icon
          v-else
          class="mr-2"
          @click="openAttachCallDialog(item)"
        >
          mdi-attachment
        </v-icon>
        <!-- <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon> -->
      </template>
      <slot
        slot="footer"
        name="footer"
      />
    </v-data-table>
  </v-card>
</template>

<script>
import { formatShortFullDate } from '@/shared/helpers/dayJsHelper';
import { getCustomerSignupsTickets } from '@/shared/apis/customerApi';
import { attachCallToTicket } from '@/shared/apis/ticketApi';
import { updateCall } from '@/shared/apis/callApi';
import AttachCallModal from '@/components/AttachCallModal.vue';
import { create } from 'vue-modal-dialogs';

const attachCallModal = create(AttachCallModal);

export default {
  props: {
    calls: Array,
    headers: Array,
    noSearch: Boolean,
  },
  data() {
    return {
      search: '',
      dialog: false,
      tickets: [],
      signups: null,
      selectedCallId: 0,
      selectedTicketId: 0,
      selectedSignupId: 0,
    };
  },
  computed: {
    hasCustomHeaders() {
      return this.headers && this.headers.length > 0;
    },
    displayHeaders() {
      let headers;
      if (this.hasCustomHeaders) {
        headers = this.headers;
      } else {
        headers = [
          {
            text: 'Date',
            sortable: false,
            value: 'createdAt',
          },
          { text: 'Call Direction', value: 'callType' },
          { text: 'Recording', value: 'recordingUrl' },
        ];
      }
      return headers;
    },
    audioStyle() {
      return this.hasCustomHeaders ? 'small-audio' : '';
    },
    filteredTickets() {
      return this.tickets;
    },
    filteredSignups() {
      return [this.signups];
    },
  },
  methods: {
    redirectToAction(item) {
      if (item.ticketId) {
        this.redirectToTicket(item);
        return;
      }

      if (item.signupId) {
        this.redirectToSignup(item);
      }
    },
    redirectToTicket(item) {
      if (this.hasCustomHeaders) {
        this.$router.push(`/customer/${item.customerId}/ticket/edit/${item.ticketId}`);
      } else {
        this.$router.push('/customer');
      }
    },
    redirectToSignup(item) {
      this.$router.push(`/signups/${item.signupId}`);
    },
    async openAttachCallDialog(item) {
      const selectedCallId = item.id;
      const phoneNumber = item.callType === 'inbound'
        ? item.from
        : item.to;

      const customerSignupsTickets = await getCustomerSignupsTickets(phoneNumber);

      const result = await attachCallModal({
        customerData: customerSignupsTickets,
      });

      console.log(result);

      if (!result) return;

      if (result.radioGroup.includes('ticket')) {
        this.attachToTicket(result.selectedId, selectedCallId);
      }

      if (result.radioGroup.includes('signup')) {
        this.attachToSignup(result.selectedId, selectedCallId);
      }
    },
    async attachToTicket(ticketId, callId) {
      const result = await attachCallToTicket({
        ticketId,
        callId,
      });

      if (result) {
        this.$store.commit('showMessage', { content: 'Successfully attached call to ticket.', color: 'success' });
        this.$emit('reload');
      } else {
        this.$store.commit('showMessage', { content: 'An error occurred while attaching call to ticket.', color: 'danger' });
      }
    },
    async attachToSignup(signupId, callId) {
      const result = await updateCall(callId, {
        signupId,
      });

      if (result) {
        this.$store.commit('showMessage', { content: 'Successfully attached call to signup.', color: 'success' });
        this.$emit('reload');
      } else {
        this.$store.commit('showMessage', { content: 'An error occurred while attaching call to signup.', color: 'danger' });
      }
    },
    getFormattedDate(date) {
      return formatShortFullDate(date);
    },
  },
};
</script>

<style lang="scss" scoped>
@use "sass:map";
@import '~vuetify/src/styles/styles.sass';

.small-audio {
  width: 200px;
}

::v-deep {
  .v-list-item__content {
    max-width: 300px;
  }
  .v-list-item:hover {
  background-color: map.get($blue, "lighten-5");
  }
}
</style>
