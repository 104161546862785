<template>
  <v-app>
    <router-view />
    <Snackbar />
    <dialogs-wrapper />
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import Snackbar from './components/Snackbar.vue';

export default {
  components: {
    Snackbar,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters([
      'isLoggedIn',
    ]),
  },
  created() {
    // if (!this.isLoggedIn) {
    //   this.$router.push('/login');
    // }
  },
};
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>
